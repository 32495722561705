.stars-rating {
    border-radius: 15px;
    padding: 100px 0;
}
.rating {
    border: solid #fff 2px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*justify-content: center;*/
    padding: 10px;
}
.stars-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.stars-rating button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}
.on {
    color: #f8e23b;
}
.off {
    color: #ccc;
}
.star {
    font-size: 50px;
}
.star:hover {
    cursor: pointer;
    position: relative;
    bottom: 10px;
}
.text-rate-me {
    padding: 18px;
    margin-bottom: 20px;
}
.text-rate-me h2 {
    font-size: 45px;
    margin-bottom: 30px;
}

.rating .submit-rating {
    background-color: #121212;
    color: #fff;
    padding: 10px;
    border: #fff solid 2px;
    z-index: 10;
    margin-left: 15px;
    font-weight: 700;
}

.rating .submit-rating:hover {
    background-color: #fff;
    color: #121212;
    border: solid #ccc 1px;
    transition: background-color 0.5s ease-in-out;
}
.rating .success {
    text-align: center;
    color: rgb(32, 163, 32);
}
.rating .failure {
    text-align: center;
    color : rgb(238, 87, 102)
}
.stars-rating p {
    color: #BBBBBB;
    font-size: 18px;
}
.text-rate-me .rating-header{
    display: flex;
    justify-content: space-between;
}
.average-rating {
    font-size: 22px;
    display: flex;
    align-items: center;
}
.average-rating .star {
    margin-left: 4px;
}






