.footer {
    padding: 30px 0 50px 0;
    background-image: url('../../assets/img/footer-bg.png');
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.footer .logo{
    font-weight: 700;
    letter-spacing: 0.6px;
    line-height: 1.2em;
    font-size: 30px;
}
.footer img {
    width: 26%;
}
.footer p {
    font-weight: 400;
    font-size: 14px;
    color: #BBBBBB;
    letter-spacing: 0.5px;
    margin-top: 20px;
}
