
.skill-form form input[type="range"] {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 10px 0;
    padding: 0;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
}

.skill-form form .sliderValue {
    font-weight: 400;
    font-size: 18px;
    color: #fff;
}