.skill {
    padding: 0 0 50px 0;
    position: relative;
}
.skill-bx {
    background-color: #151515;
    border-radius: 64px;
    text-align: center;
    padding: 60px 50px;
    margin-top : -60px;
}
.skill h5 {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.skill p {
    color: #BBBBBB;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5rem;
    margin: 14px 0 75px 0;
    text-align: start;
}
.skill-slider {
    width: 90%;
    margin: 0 auto;
    position: relative;
 
}
.background-image-left {
    top : 20%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
}