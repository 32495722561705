.projects-base {
    padding: 130px 0 0 0;
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/img/color-sharp.png');
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
}
.projects-base .container .row {
    margin-top: 5px;
    padding: 10px;
}
.projects-base .danger {
    margin: 20px 0;
}


