
.auth-form {
    height: 90%;
}
.auth-form form {
    min-height: 100%;
    padding: 60px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background : linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91% , rgba(74, 47, 189, 0.50) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.auth-form input {
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  .auth-form form input:focus{
    background: rgba(255, 255, 255, 1);
    color: #121212;
  }
  .auth-form form input::placeholder{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
  .auth-form form input:focus::placeholder{
    color: #121212;
    opacity: 0.8;
  }
  .auth-form form button {
    font-weight: 700;
    color:  #fff;
    background-color:#000;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
    border: solid #fff 1px;
  }
  .auth-form form button:hover {
    color: #121212;
    background-color: red;
    border-radius: 10px;
    border : solid 1px #121212;
  }
  .auth-form form button span {
    z-index: 1;
    position: relative;
  }
  .auth-form form button::before {
    content: "";
    background: #fff;
    color: #121212;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
    border-radius: 10px;
  }
  .auth-form form button:hover::before {
    width: 100%;
    color: #121212;
    border : solid 1px #121212;
  }
  .auth-form label {
    font-size: 18px;
    margin-bottom: 6px;
  }
  .auth-form-errors {
    list-style: none;
  }
  .auth-form-errors p {
    text-align: left;
    font-size: 14px;
    margin-left: 6px;
  }