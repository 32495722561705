.item {
    width: 160px;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
}
div.outer {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}
div.inner {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#number {
    font-family: 'Centra', sans-serif;
    font-weight: 500;
    font-style: bold;
}
circle {
    fill: none;
    stroke: url(#GradientColor);
    stroke-width: 20px;
}

svg {
    position: absolute;
    top : 0;
    left : 0;
}
.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}
