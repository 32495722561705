
.projects {
    padding: 80px 0;
    position: relative;
    background-color: black;
}
.projects h2 {
    font-size: 45px;
    font-weight: 700;
    text-align: center;
}

.projects p {
    color: #BBBBBB;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px auto 30px auto;
    text-align: center;
    width: 56%;
}
.projects .nav.nav-pills {
    width: 72%;
    margin: 0 auto;
    border-radius: 50px;
    background-color: rgb(255 255 255 / 10%);
   /* overflow: hidden;*/
    overflow-x: auto;
}
.projects .nav.nav-pills .nav-item {
    width: 33.33333%;
}
.projects .nav.nav-pills .nav-link {
    background-color: transparent;
    border-radius: 0;
    padding: 17px 0;
    color: #fff;
    width: 100%;
    font-size: 17px;
    letter-spacing: 0.8px;
    font-weight: 500;
    position: relative;
    transition: 0.3s ease-in-out;
    text-align: center;
    z-index: 0;
}
.projects .nav.nav-pills .nav-link::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    top : 0;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
   
}
.projects .nav.nav-pills .nav-link.active::before {
    width: 100% !important;
}
.projects .nav.nav-pills .nav-link.active {
    border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 0px 0px 55px;
    overflow: hidden;
}
.nav-link#projects-tabs-tab-second {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 55px 55px 0;
}


.project-imgbx {
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 24px;
    height: 500px;
    border: 1px solid rgba(255, 255, 255, 0.7);
}
.project-imgbx::before {
    content: "";
    background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
    opacity: 0.80;
    position: absolute;
    width: 100%;
    height: 0;
    transition: 0.4s ease-in-out;
}
.project-imgbx:hover::before {
    height: 100%;
}
.project-txtx {
    position: absolute;
    text-align: start;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s ease-in-out;
    width: 100%;
    padding: 10%;
    height: 80%;
    overflow-y: auto;
}
.project-txtx .project-details {
    display: none;
}
.project-imgbx img {
    opacity: 0.4;
}
.project-imgbx:hover .project-txtx {
    top : 50%;
    opacity: 1;
}
.project-imgbx:hover .project-txtx h4, .project-imgbx:hover .project-txtx .used-tech{
    display: none;
    transition: display 0.8s ease-in-out;
}
.project-imgbx:hover .project-details{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.project-txtx h4 {
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.1em;
    margin-bottom: 6%;
    text-align: center;
}

.project-txtx .project-details span {
    text-align: start;
    font-style: italic;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.8px;
}

.project-txtx .used-tech {
    position: relative;
    top : 0%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.used-tech h5 {
    text-decoration: underline;
    margin-bottom: 8px;
}


.project-txtx button {
    position: relative;
    top : 30%;
    margin-bottom: 16%;
}
.background-image-right {
    top: 20%;
    position: absolute;
    bottom: 0;
    width: 35%;
    right: 0;
    z-index: -4;
}



























































































