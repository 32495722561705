.delete-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 30%;
    justify-content: space-between;
    width: fit-content;
    padding: 20px 40px;
    border-radius: 20px;
    border: solid 1px #666;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 10;
    filter: none;
}
.buttons-delete-project{
    display: flex;
    justify-content: space-around;
}

.buttons-delete-project button {
    font-weight: 700;
    color:  #fff;
    background-color:#000;
    padding: 14px 48px;
    font-size: 18px;
    margin-top: 25px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;
    margin-left: 30px;
    border: solid 1px #fff;
}
.buttons-delete-project button span {
    z-index: 1;
    position: relative;
}
.buttons-delete-project button:hover {
    color: #121212;
    border : solid 2px #666;
}
.buttons-delete-project button::before {
    content: "";
    background: #fff;
    color: #121212;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    transition: 0.3s ease-in-out;
    border : solid 2px #666;
}
.buttons-delete-project button:hover::before {
    width: 100%;
}
.delete-confirmation h3 {
    font-weight: 700;
    color : #666;
    margin-bottom: 10px;
}

