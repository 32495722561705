.all-projects, .all-skills {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
    max-width: 100%;
}
.blurred-background {
    filter: blur(5px);
}
.projects-index, .skills-index{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
