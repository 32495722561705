.skill-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}
.skill-detail-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border : solid white 2px;
    border-radius: 25px;
}
.no-skill-match{
    display: flex;
    justify-content: center;
    padding: 20px;
    font-size: 26px;
}