.go-back {
    font-weight: 700;
    color: #BBBBBB;
    border: 1px solid #fff;
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}
.go-back span {
    z-index: 1;
    margin-left: 6px;
}
.go-back::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background : linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91% , rgba(74, 47, 189, 0.50) 111.58%);
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.go-back:hover {
    color: #fff;
    background-color: transparent;
    border : solid #666 2px;
    border-radius: 10px;
}
.go-back:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}
.go-back:hover svg {
    animation: moveToLeft 2s linear infinite;
}
@keyframes moveToLeft {
    0% {
        transform: translateX(-6px);
    }
    50% {
        transform: translateX(-20px);
    }
    100% {
        transform: translateX(-6px);
    }
}