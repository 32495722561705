.project-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.project-detail-header h3 span {
    text-decoration: underline;
}
.github-repo{
    font-weight: 700;
    color: #BBBBBB;
    border: 1px solid #fff;
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
}
.project-detail-img {
    border: solid 1px #666;
    min-height: 370px;
    overflow: hidden;
    border-radius: 35px;
}
.project-detail-img img {
    height: 100%;
    width: 100%;
    object-fit : cover;
}
.project-detail-right section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: solid 1px #666;
    padding: 15px 30px;
    background-color: #151515;
    z-index: 0;
    border-radius: 35px;
}
.project-detail-right section h4 {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 10px;
    text-transform: capitalize;
}
.project-detail-right p {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 10px;
    letter-spacing: 0.8px;
    color: #BBBBBB;
    line-height: 1.5rem;
}
.github-repo {
    z-index: 1;
}
.github-repo::before {
    content: "";
    width: 0%;
    height: 100%;
    position: relative;
    background : linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91% , rgba(74, 47, 189, 0.50) 111.58%);
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
}
.github-repo:hover {
    color: #fff;
    background-color: transparent;
    border : solid #666 2px;
    border-radius: 10px;
}
.github-repo:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
}
.no-project-match, .loading {
    color: #BBBBBB;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
}